/* button css */

button {
  border-color: black;
  padding: 0px 30px;
  background-color: #630f42;
  color: white;
  border-width: 1px;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
}
button:focus {
  outline: 0;
}

button:hover {
  background-color: white;
  color: black;
}

button h4 {
  width: 100%;
  font-size: 1.2em;
  margin: 0;
}

/* This Is the modal */

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Video Css */

.video-container {
  width: 80%;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

video {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Nav Bar Css */

.navbar img {
  height: 60px;
  width: auto;
}

.navbar ul {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 2;
}

.navbar li {
  letter-spacing: 0px;
  font-size: 1.8rem;
}

.navbar a {
  text-decoration: none;
}

.navbar a:visited {
  text-decoration: none;
  color: #0d2b3a;
}

.selected {
  font-weight: 500;
  color: #0d2b3a;
  text-decoration: underline;
  border-bottom: 1.5px solid #0d2b3a;
}

.nav-socials {
  color: #0d2b3a;
}
.nav-socials a {
  color: #0d2b3a;
}
.nav-socials a:visited {
  color: #0d2b3a;
}

.nav-bars {
  color: #0d2b3a;
  font-size: 3rem;
  position: fixed;
}

/* Footer Css */

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #0d2b3a;
}

.footer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo {
  display: flex;
  height: 50px;
  width: auto;
}

li {
  list-style: none;
  font-weight: 300;
  font-size: 16px;
  color: #fffbff;
}

ul {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}

.bottom {
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;

  color: #fffbff;
}
.bottom h4 {
  padding: 5px 0 0 10px;
}

.alt-color {
  color: #caffef;
}

.footer-socials {
  color: snow;
}
.footer-socials a:visited {
  color: snow;
}

/* Social Icons */

.socials {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

.social-icon {
  display: flex;
  align-items: center;
  font-size: 3em;
  justify-content: space-around;
  padding: 10px;
}

a {
  color: inherit;
}

/* Page Containers */

.info-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  padding: 10px 0;
}
.what-we-do-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.testimonials-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.image-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: auto;
  opacity: 0.8;
  overflow: hidden;
}
image {
  height: 100%;
}

.about-container {
  width: 100%;
  border-color: #0d2b3a;
  border-width: 0px 0 5px 0;
  border-style: solid;
  background: linear-gradient(to top, #fbfbed 50%, snow 100%);
}

.event-hire {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-color: #0d2b3a;
  border-width: 0px 0 5px 0;
  border-style: solid;
  background-color: snow;
  padding: 30px 0 0 0;
}
.event-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.video-hire {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-color: #0d2b3a;
  border-width: 5px 0 0 0;
  border-style: solid;
  background-color: snow;
  padding: 30px 0 0 0;
}

.info-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.what-we-do-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.image-container {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: auto;

  overflow: hidden;
}

/* Miscellanious */

.quote {
  font-style: italic;
}

.web-icon {
  font-size: 2rem;
}

#services-array {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: snow;
  font-size: 3em;
  text-align: center;
}
.services-array-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d2b3a;
  width: 100%;
  height: 6em;
  padding: 20px 0 20px 0;
  margin-top: 10px;
}
/* cards */

.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0px;
  border-radius: 20px;
}

.image-crop {
  height: 170px;
  overflow: hidden;
}
.card-container img {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.more-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card {
  width: 300px;
  height: 380px;
  box-shadow: 1px 1px 10px grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 20px;
  background-color: snow;
  border-radius: 5px;
}

.video-card {
  width: 300px;
  height: 400px;
  box-shadow: 1px 1px 10px grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 25px;
  margin-bottom: 25px;
  background-color: snow;
  border-radius: 5px;
}

.card-text {
  padding: 10px 10px 10px 10px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.event-more-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h6 {
  padding: 5px 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.home-page-container {
  height: 50%;
  display: flex;
  justify-content: center;
}
.home-page-container-alt {
  visibility: hidden;
  height: 0;
}
.JPSSVG {
  width: 60vw;
  height: 50vh;
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 1025px) {
  .navbar {
    position: relative;
    display: flex;

    margin: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background-color: snow;
    padding: 5px 20px;
    border-bottom: solid 5px #0d2b3a;
  }
  .menu {
    display: none;
  }
}
.TextLogoSVG {
  display: flex;
  justify-content: center;
}

.youtube-video {
  width: 100%;
  height: calc(100vh - 110px);
}

@media screen and (max-width: 1024px) {
  .what-we-do-container {
    flex-direction: column;
    text-align: center;
  }
  .testimonials-container {
    flex-direction: column-reverse;
    text-align: center;
  }
  .info-container {
    width: 90%;
  }
  .info-container h3 {
    width: 80%;
  }

  .card-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-row {
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
  }
  .bottom {
    justify-content: center;
    text-align: center;
  }

  .navbar {
    display: none;
  }
  .sideBar-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .sideBar {
    position: fixed;
    right: 0;
    height: 100vh;
    justify-content: space-around;
    border-left: 1px solid #0d2b3a;
    z-index: 1;
    background-color: snow;
    padding: 20px;
  }

  .sideBar ul {
    height: 80%;
    justify-content: space-around;
  }
  .sideBar-links {
    right: 0;
    height: 80%;
    flex-direction: column;
    align-items: space-around;
  }

  .sideBar a {
    text-decoration: none;
  }

  .sideBar a:visited {
    text-decoration: none;
    color: #0d2b3a;
  }
  .menu {
    position: relative;
  }

  .menu-icon {
    right: 0;
    padding: 20px 20px;
    height: 30px;
    width: 23px;
    display: flex;
    position: fixed;
    font-size: 2.5rem;
    color: #0d2b3a;
    z-index: 2;
  }
  .menu-icon {
    filter: drop-shadow(0px 0 5px snow);
  }
  .menu-icon-close {
    right: 0;
    padding: 20px 20px;
    height: 33px;
    width: 35px;
    display: flex;
    position: fixed;
    font-size: 2.5rem;
    color: #0d2b3a;
    z-index: 2;
  }
  .menu path {
    padding: 0;
    background-color: snow;
  }
  .JPSSVG {
    width: 80vw;
    height: 25vh;
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .TextLogoSVG {
    visibility: hidden;
  }
  .home-page {
  }
  .home-page-container {
    visibility: hidden;
    height: 5vh;
  }
  .home-page-container-alt {
    margin: 0 auto;
    visibility: initial;
    height: initial;
    width: 70%;
  }
  .container {
    width: 100%;
  }

  .card-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .what-we-do-container {
    flex-direction: column;
  }
  .testimonials-container {
    flex-direction: column-reverse;
  }
  .video-hire-container {
    width: 100%;
  }
  .event-hire-container {
    width: 100%;
  }
  .eventText {
    text-align: center;
  }
  .video-text {
    text-align: center;
    padding: 0 40px;
  }
}
