@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;500&display=swap');

*{
  margin:0;
}

body{
  background-color:  #d3c1c3;
}

li{
  font-size: 1rem;
}

h1{
  font-family: 'Work Sans';
  font-size: 2rem;
  font-weight: 300;
  color: #0d2b3a;
}

h2{
  font-family: 'Work Sans';
  font-size: 2rem;
  font-weight: 300;
  color: #0d2b3a;
  
}

h3{
  font-family: 'Work Sans';
  font-size: 1.2rem;
  font-weight: 200;
  color: #848482;
  padding:0px;
}

h4{
  font-family: 'Work Sans';
  font-size: 1rem;
  font-weight: 200;
  width: 80%;
  padding: 10px 0;
}

h5{
  font-family: 'Work Sans';
  font-weight: 200;
  color: #0d2b3a;
}

h6{
  font-family: 'Work Sans';
  color: #630F42 ;
  font-weight: 200;
  font-size: 1rem;
}

body{
    font-family: 'Work Sans';
    font-size: 1em;
    font-weight: 300;
    margin: 0;
    padding: 0;
    background-color: #fefefe;
}

img {
  width: 100%;
}