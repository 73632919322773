@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;500&display=swap');

.outer-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    background-color: snow;
    width: 60%;
    
    border-radius: 10px;
}

.contact-container{
    display:flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    box-shadow: 1px 1px 5px grey;
    padding:10px;
    border-radius: 5px;
}
.name-email{
    display: flex;
    width: 100%;
}


form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    background-color: snow;
}


input{
    display:flex;
    border-width: 0 0 1px 0;
    width: 100%;
    font-family: 'Work Sans';
    font-weight: 300;
    color: black;
    font-size: 1em;
    padding: 10px 0;
    margin: 2px;
    background-color: snow;
}

input:focus{
    outline: none;
    border-bottom: solid 2px #630F42 ;
}

.message {
    display:flex;
    border-width: 0 0 1px 0;
    width: 100%;
    font-family: 'Work Sans';
    font-weight: 300;
    color: black;
    font-size: 1em;
    padding: 10px 0;
    margin: 2px;
    resize: none;
    overflow: visible;
    background-color: snow;
}

.message:focus{
    outline: none;
    border-bottom: solid 2px #630F42 ;
}

.contact-close{
    display: flex;
    justify-content: space-between;
}

.Button{
    width:50%;
    
}

@media screen and (max-width: 750px){
    .name-email{
        flex-direction: column;
    }
    form{
        text-align: left !important;
    }
    .outer-container{
        text-align: left !important;
    }
    .outer-container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0 auto;
        background-color: snow;
        width: 100%;
        height:80%;
        z-index: 3;
        border-radius: 10px;
    }

    .contact-container{
        height: 100%;
    }
    
    .contact-close{
        
        margin-top: 10px;
        margin-right: 10px;
    }
}